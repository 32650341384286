import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  Inject,
  ChangeDetectorRef,
  ViewRef,
  OnDestroy,
  ComponentRef,
  AfterViewInit,
} from '@angular/core';
import { NOTICE_TYPE } from '@core/constants';
import { NOTICE_ICON_MAP } from '@core/constants/notice-icon.map';
import { APP_CONFIG } from '@core/interfaces/configs';
import { NoticeService } from '@core/services/notice.service';
import { APPCONFIG } from '@core/tokens/configs';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgStyle, NgIf } from '@angular/common';

@Component({
  selector: 'pbb-notice',
  templateUrl: './pbb-notice.component.html',
  styleUrls: ['./pbb-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    role: 'status',
    'aria-live': 'assertive',
  },
  standalone: true,
  imports: [NgClass, MatIconModule, NgStyle, NgIf, MatButtonModule, TranslateModule],
})
export class PbbNoticeComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('noticeElem', {
    static: true,
  })
  noticeElem!: ElementRef;
  @Input() inline: boolean = false;
  @Input() showInContainer: boolean = true;
  @Input() type: NOTICE_TYPE = NOTICE_TYPE.INFO;
  @Input() icon?: string;
  @Input() iconColor?: string;
  @Input() title!: string;
  @Input() fullTitle!: string;
  @Input() subtitle!: string;
  @Input() duration?: number = this.appConfig.noticeTimeout;
  inContainer: boolean = false;
  @Input() dismissable: boolean = false;
  showFullTitle = false;

  class: string = '';
  containerElem?: ComponentRef<PbbNoticeComponent>;

  constructor(
    @Inject(APPCONFIG) private readonly appConfig: APP_CONFIG,
    @Inject(ChangeDetectorRef) private view: ViewRef,
    private ns: NoticeService,
    private cdr: ChangeDetectorRef
  ) {}

  hide() {
    this.noticeElem.nativeElement.style.display = 'none';
  }

  ngOnInit(): void {
    this.class = NOTICE_TYPE[this.type].toLowerCase();
    this.icon = this.icon || NOTICE_ICON_MAP.get(this.type);
    if (this.duration && this.duration > 0) {
      setTimeout(() => {
        this.hide();
      }, this.duration);
    }

    this.cdr.detectChanges();
  }

  change(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.showInContainer && this.containerElem) {
      this.containerElem?.destroy();
    }
  }

  ngAfterViewInit() {
    if (!this.inContainer && this.showInContainer) {
      this.containerElem = this.ns.addNotice({
        inline: this.inline,
        type: this.type,
        icon: this.icon,
        iconColor: this.iconColor,
        title: this.title,
        fullTitle: this.fullTitle,
        subtitle: this.subtitle,
        duration: this.duration,
      });
      this.noticeElem.nativeElement.style.display = 'none';
    }
  }

  dismiss() {
    this.ns.clearNotices();
  }
}
