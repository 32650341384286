import { Injectable, OnDestroy, ViewContainerRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { PbbNoticeComponent } from '@core/components/pbb-notice/pbb-notice.component';
import { NoticeContainerDirective } from '@core/directives/notice-container.directive';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NoticeService implements OnDestroy {
  viewContainerRef?: ViewContainerRef;
  private sub = this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((e) => {
    this.clearNotices();
  });

  constructor(private router: Router) {}

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  init(noticeContainer: NoticeContainerDirective) {
    if (noticeContainer) {
      this.viewContainerRef = noticeContainer.viewContainerRef;
      const container: HTMLElement = this.viewContainerRef.element.nativeElement.parentElement;
      if (container) {
        container.ariaAtomic = 'true';
        container.ariaLive = 'assertive';
      }
    }
  }

  addNotice(notice: Partial<PbbNoticeComponent>) {
    if (!this.viewContainerRef) {
      setTimeout(() => {
        this.addNotice(notice);
      }, 10);
      return;
    }
    const elem = this.viewContainerRef?.createComponent(PbbNoticeComponent);
    Object.assign(elem.instance, notice);
    elem.instance.inContainer = true;
    elem.instance.ngOnInit();
    return elem;
  }

  clearNotices() {
    this.viewContainerRef?.clear();
  }
}
